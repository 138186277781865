<template>
    <svg viewBox="0 0 354 352" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="99.4366242%" x2="100%" y2="0.563375786%" :id="_uid">
                <stop stop-color="var(--color-image-main)" stop-opacity="0.401606206" offset="0%"></stop>
                <stop stop-color="var(--color-image-main)" stop-opacity="0.100442526" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill-rule="nonzero">
                <g :fill="`url(#${ _uid })`">
                    <path d="M117.51529,12.1691215 C68.6330229,28.2431148 28.0736362,67.9001201 10.3728634,116.356757 C-7.32790942,164.813394 -2.05284467,221.013706 23.7363607,265.715833 C73.5564166,351.600235 201.447431,379.289742 282.214533,321.681489 C362.981636,263.955907 378.572382,133.838691 313.747698,58.6311751 C248.923013,-16.576341 118.101408,-19.9788651 49.4083425,51.59147"></path>
                </g>
                <g transform="translate(41.000000, 45.000000)">
                    <g transform="translate(40.947403, 90.463725)">
                        <path d="M154.179336,146.504241 C154.179336,146.504241 91.4851851,161.072425 66.3605628,135.930558 L75.4007307,75.7780555 C78.1010405,58.5077079 76.4573736,25.1418665 89.7241135,13.3933307 C92.3070185,11.1611089 94.7725189,9.98625537 94.7725189,9.98625537 C104.047496,5.52181179 120.60157,3.05391929 136.451215,3.05391929 C155.705598,2.93713393 175.547005,17.6228036 180.595411,36.1854901 L159.697361,85.0593987 L154.179336,146.504241 Z" fill="#FFFFFF"></path>
                        <path d="M147.956883,43.4695822 C147.956883,43.4695822 176.016625,97.6303319 162.045456,100.449981 C116.257593,109.966294 89.6067087,117.720328 89.6067087,117.720328 L87.2586131,135.578102 C87.9630418,138.280266 180.008387,135.930558 190.927031,123.594596 C198.675747,114.900679 208.889962,94.693198 187.052674,50.8711597 L180.478006,35.9505193 L147.956883,43.4695822 Z" fill="#FFE6D5"></path>
                        <path d="M95.242138,15.6255525 L25.3862956,42.7646701 L23.1556049,5.99175322 L8.2451982,0.234970715 C8.2451982,0.234970715 -13.4746856,59.2126201 13.6458179,70.1387583 C40.7663214,81.0648965 71.4089682,60.8574151 71.4089682,60.8574151 L95.242138,15.6255525 Z" fill="#FFE6D5"></path>
                        <path d="M103.930091,6.57918001 C79.5098979,14.3332136 64.9517055,23.4970715 30.6695106,37.8302851 L48.2802272,77.1878798 L78.6880644,67.4365951 L103.930091,6.57918001 Z" fill="#FFFFFF"></path>
                        <polyline fill="#FFFFFF" points="143.730311 35.9505193 161.458432 77.1878798 201.493461 69.9037876 174.490362 23.4970715 142.673668 35.9505193"></polyline>
                        <path d="M122.832261,64.1470051 C131.402809,63.6770637 139.738548,64.0295197 148.309097,64.1470051 C149.013526,64.1470051 149.013526,63.0896369 148.309097,62.9721515 C139.973358,61.9147833 131.285405,62.3847247 122.832261,63.0896369 C122.127832,63.2071222 122.127832,64.1470051 122.832261,64.1470051 L122.832261,64.1470051 Z" fill="#333333"></path>
                        <path d="M160.988813,16.2129793 C132.224643,18.7976572 151.948645,61.3273565 159.227741,74.1332605 C159.697361,74.8381726 160.754004,74.2507458 160.284384,73.4283483 C153.005288,60.3874737 133.398691,20.9123936 160.988813,16.8004061 C161.458432,16.9178915 161.341027,16.2129793 160.988813,16.2129793 L160.988813,16.2129793 Z" fill="#333333"></path>
                        <path d="M81.2709695,40.5324483 C77.7488262,54.6306912 75.4007307,69.4338462 74.4614924,84.0020305 C74.4614924,84.5894573 75.4007307,84.7069426 75.5181354,84.1195158 C77.6314214,69.6688169 79.6273026,55.2181179 81.9753982,40.767419 C81.9753982,40.2974776 81.3883743,40.0625068 81.2709695,40.5324483 L81.2709695,40.5324483 Z" fill="#333333"></path>
                    </g>
                    <g transform="translate(129.000986, 0.000000)">
                        <path d="M60.490324,52.5159547 C60.9599431,46.4067161 61.3121574,40.2974776 61.7817765,34.3057243 C66.5953724,33.0133854 69.0608727,26.7866615 67.1823963,22.2047325 C65.3039198,17.6228036 59.9033001,15.0381257 54.9722995,15.7430379 C58.0248237,13.6283014 57.7900141,8.57643108 55.3245138,5.87426787 C52.7416087,3.17210465 48.7498463,2.23222179 44.9928934,2.11473643 C41.2359405,1.99725107 37.4789877,2.4671925 33.7220348,1.76228036 C30.4347011,1.17485357 27.2647721,-0.352456072 23.9774383,0.117485357 C20.6901046,0.587426787 17.5201756,4.22947286 19.2812472,7.04912144 C14.5850562,6.34420929 9.41924597,5.75678251 5.19267399,7.9890043 C0.966102021,10.3387114 -1.14718397,16.8004061 2.49236412,19.9725107 C0.144268582,21.1473643 -0.56016008,24.671925 0.848697244,27.0216322 C2.14014979,29.3713393 4.95786444,30.5461929 7.65817431,30.7811636 C10.3584842,31.0161343 13.0587941,30.3112222 15.6416991,29.60631" fill="#333333"></path>
                        <path d="M59.7858953,40.6499336 L58.8466571,53.3383522 C58.8466571,53.3383522 69.5304918,54.7481765 66.4779676,64.6169465 C63.4254434,74.3682312 56.0289424,70.1387583 56.0289424,70.1387583 C56.0289424,70.1387583 54.150466,95.2806248 28.4388198,87.2916205 C8.01038864,80.9474112 9.18443641,54.3957204 11.8847463,31.8385318 C12.9413893,23.02713 21.1597237,17.0353768 29.8476772,18.6801718 L45.3451077,21.7347911 C54.150466,23.4970715 60.3729192,31.6035611 59.7858953,40.6499336 Z" fill="#FFE6D5"></path>
                        <path d="M58.7292523,63.794549 L51.8023705,100.802437 C50.2761084,109.143897 42.057774,114.430738 33.8394396,112.550972 L33.8394396,112.550972 C26.2081291,110.671207 21.2771284,103.269629 22.6859858,95.5155955 L26.4429386,74.3682312 L58.7292523,63.794549 Z" fill="#FFE6D5"></path>
                        <path d="M30.3172963,64.2644905 C30.3172963,64.2644905 30.3172963,64.3819758 30.3172963,64.6169465 C30.1998915,64.7344319 30.0824867,65.086888 29.7302724,65.2043733 C29.3780581,65.3218587 28.7910342,65.3218587 28.3214151,64.9694026 C27.851796,64.7344319 27.4995816,64.1470051 27.2647721,63.5595783 C26.795153,62.3847247 26.9125577,60.8574151 27.0299625,59.4475908 C27.1473673,57.9202812 27.1473673,56.2754862 27.2647721,54.5132058 C27.7343912,47.4640844 28.3214151,41.8247872 28.6736294,41.8247872 C29.0258437,41.8247872 28.908439,47.5815697 28.4388198,54.5132058 C28.3214151,56.2754862 28.2040103,57.9202812 28.0866055,59.4475908 C27.9692007,60.9749004 27.851796,62.2672394 28.0866055,63.3246076 C28.3214151,64.3819758 29.0258437,64.8519172 29.4954628,64.7344319 C30.0824867,64.6169465 30.1998915,64.1470051 30.3172963,64.2644905 Z" fill="#333333"></path>
                        <path d="M21.9815571,84.7069426 C22.0989619,84.5894573 23.2730097,85.4118548 25.3862956,86.3517376 C27.4995816,87.2916205 30.5521058,88.2315033 34.0742491,88.3489887 C37.5963925,88.4664741 40.7663214,87.6440766 42.8796074,86.7041937 C44.9928934,85.8817962 46.284346,85.0593987 46.4017507,85.1768841 C46.5191555,85.2943694 45.4625125,86.3517376 43.2318218,87.4091058 C41.1185358,88.4664741 37.831202,89.5238423 34.0742491,89.5238423 C30.3172963,89.4063569 27.0299625,88.2315033 25.0340813,87.0566498 C22.9207953,85.8817962 21.8641523,84.824428 21.9815571,84.7069426 Z" fill="#333333"></path>
                        <path d="M27.0299625,69.4338462 C27.1473673,69.3163608 27.7343912,69.9037876 28.7910342,70.373729 C29.8476772,70.8436705 31.491344,71.1961265 33.1350109,70.7261851 C34.7786778,70.1387583 35.9527256,68.9639047 36.5397495,67.9065365 C37.1267733,66.8491683 37.2441781,66.1442562 37.4789877,66.1442562 C37.5963925,66.1442562 37.831202,66.9666537 37.3615829,68.2589926 C36.8919638,69.5513315 35.717916,71.1961265 33.60463,71.9010387 C31.491344,72.6059508 29.4954628,71.9010387 28.4388198,71.1961265 C27.2647721,70.373729 26.9125577,69.5513315 27.0299625,69.4338462 Z" fill="#333333"></path>
                        <path d="M59.5510858,65.7918001 C59.5510858,65.5568294 60.1381096,65.5568294 60.8425383,65.086888 C61.1947526,64.8519172 61.546967,64.4994612 61.6643717,64.0295197 C61.7817765,63.5595783 61.6643717,63.0896369 61.3121574,63.0896369 C61.0773479,62.9721515 60.7251335,63.0896369 60.7251335,63.2071222 L59.6684905,62.6196954 C60.6077288,60.6224444 61.546967,59.2126201 61.7817765,59.3301054 C62.0165861,59.4475908 61.546967,60.9749004 60.7251335,63.0896369 L59.6684905,62.5022101 C60.1381096,61.7972979 61.0773479,61.5623272 61.7817765,61.9147833 C62.2513956,62.0322687 62.7210147,62.6196954 62.8384195,63.0896369 C62.9558243,63.5595783 62.9558243,64.0295197 62.7210147,64.3819758 C62.4862052,65.2043733 61.7817765,65.6743147 61.1947526,65.7918001 C60.2555144,66.2617415 59.5510858,66.0267708 59.5510858,65.7918001 Z" fill="#333333"></path>
                        <path d="M39.8270832,40.8849043 C39.7096784,40.5324483 41.001131,39.8275361 42.7622026,40.2974776 C44.4058695,40.767419 45.227703,42.0597579 44.9928934,42.1772433 C44.7580839,42.412214 43.7014409,41.8247872 42.4099883,41.4723311 C41.1185358,41.1198751 39.944488,41.2373604 39.8270832,40.8849043 Z" fill="#333333"></path>
                        <path d="M17.5201756,37.3603436 C17.4027708,37.1253729 18.6942234,36.1854901 20.5726998,36.3029754 C22.4511762,36.4204608 23.625224,37.5953143 23.3904144,37.8302851 C23.1556049,38.1827411 21.9815571,37.5953143 20.455295,37.477829 C18.9290329,37.477829 17.7549851,37.7127997 17.5201756,37.3603436 Z" fill="#333333"></path>
                        <path d="M58.7292523,53.3383522 C51.5675609,46.2892308 49.4542749,34.6581804 47.4583937,24.7894104 C40.7663214,29.60631 31.7261536,30.7811636 23.9774383,27.8440297 C21.6293428,30.6636783 18.6942234,33.1308708 15.6416991,35.1281218 C14.7024609,35.7155486 13.6458179,36.3029754 12.7065797,35.9505193 C11.532532,35.5980633 10.9455081,34.188239 10.7106985,33.0133854 C9.7714603,29.1363686 9.7714603,24.9068958 11.1803176,21.1473643 C12.5891749,17.3878329 15.4068896,13.9807575 19.1638425,12.5709332 C23.1556049,11.0436236 27.7343912,11.7485357 31.8435584,12.8059039 C38.0660116,14.4506989 44.17106,16.9178915 49.8064893,20.2074815 C55.3245138,23.3795861 60.6077288,27.7265443 62.9558243,33.6008122 C65.3039198,39.4750801 65.3039198,51.1061304 59.6684905,54.0432644" fill="#333333"></path>
                        <ellipse fill="#333333" transform="translate(41.830304, 49.293583) rotate(-85.919583) translate(-41.830304, -49.293583) " cx="41.8303038" cy="49.2935827" rx="1.9973139" ry="1"></ellipse>
                        <ellipse fill="#333333" transform="translate(21.250556, 46.565353) rotate(-85.919864) translate(-21.250556, -46.565353) " cx="21.2505558" cy="46.565353" rx="1.9973132" ry="1"></ellipse>
                    </g>
                    <path d="M48.3707681,91.8735494 C48.3707681,91.8735494 51.4232923,72.7234362 55.1802452,72.9584069 C58.9371981,73.1933776 66.333699,72.9584069 66.333699,72.9584069 C66.333699,72.9584069 66.9207229,54.7481765 69.0340089,57.6853104 C71.1472949,60.6224444 71.7343188,85.0593987 63.7507939,96.6904491 L48.3707681,91.8735494 Z" fill="#FFE6D5"></path>
                    <path d="M130.554112,208.184053 C130.554112,208.184053 97.3285602,214.058321 97.915584,218.287794 C98.6200127,222.634752 100.850703,226.98171 100.850703,226.98171 L130.436707,226.276798 L130.436707,208.184053 L130.554112,208.184053 Z" fill="#FFE6D5"></path>
                    <g transform="translate(0.000000, 157.430379)">
                        <path d="M166.714783,70.7261851 L166.597379,68.1415072 C166.479974,66.9666537 165.540736,66.0267708 164.366688,65.9092855 L97.915584,61.7972979 L75.4912716,1.87976572 C75.1390573,0.939882859 74.1998191,0.352456072 73.1431761,0.352456072 L2.81771465,4.22947286 C1.29145255,4.34695822 0.234809554,5.87426787 0.821833439,7.40157751 L25.8290509,71.5485826 L166.714783,70.7261851 Z" fill="#7E8083"></path>
                        <ellipse fill="#CBCCCE" opacity="0.41" transform="translate(46.729397, 33.488921) rotate(-3.150589) translate(-46.729397, -33.488921) " cx="46.7293972" cy="33.4889214" rx="5.51809933" ry="5.63935023"></ellipse>
                    </g>
                </g>
                <path d="M323.471981,271 L29,274.651786 C29,274.651786 29,274.651786 29,274.651786 C82.5082873,353.577486 204.342541,377.255196 282.429361,321.536008 C289.485399,316.470627 295.953433,310.934048 301.951066,304.926271 C302.539069,304.337273 303.244672,303.748276 303.832676,303.041478 C312.770324,293.853113 320.414365,283.722352 327,272.766993 L323.471981,271 Z" fill="#ADAFB2"></path>
                <g transform="translate(95.000000, 145.000000)" fill="var(--color-image-secondary-2)">
                    <path d="M4.61398029,23.9475106 C4.49417065,22.0038025 3.89512248,20.1744302 4.61398029,18.2307221 C6.0516959,14.3433058 9.40636566,14.5719774 12.7610354,15.257992 C16.2355148,16.0583424 19.3505653,16.2870139 22.3458062,14.1146343 C27.0183819,10.6845612 27.6174301,2.5667214 21.746758,0.165670199 C19.8298038,-0.6346802 18.7515171,1.65203523 19.2307557,3.02406448 C20.0694231,4.96777259 21.6269483,7.02581647 20.3090424,9.19819613 C18.3920882,12.0565904 15.0374185,10.7988969 12.4016065,10.3415538 C9.52617529,9.76987498 6.65074407,10.2272181 4.13474175,11.8279189 C-0.0585954506,14.6863131 -1.9755496,21.5464594 2.81683577,24.747861 C3.53569358,25.3195399 4.73378992,24.8621968 4.61398029,23.9475106 L4.61398029,23.9475106 Z"></path>
                    <path d="M50.4089991,79.1233524 C51.3637464,77.9293186 52.079807,76.6158814 53.5119281,75.8994611 C56.3761702,74.4666205 58.0469781,76.2576712 59.4790992,78.5263355 C60.9112203,80.7949997 62.4626848,82.5860505 65.3269269,82.9442606 C69.7426336,83.5412775 74.397027,79.2427558 72.6068757,74.8248306 C72.0101586,73.39199 70.2200072,74.1084103 69.7426336,75.1830408 C69.1459164,76.7352847 68.9072296,78.6457389 66.9977348,79.1233524 C64.3721795,79.8397727 63.2980887,77.3323016 62.1046545,75.5412509 C60.7918768,73.7502002 59.1210689,72.436763 56.8535439,72.0785528 C53.0345544,71.4815359 48.2608174,74.3472171 49.0962214,78.6457389 C49.0962214,79.3621591 50.0509688,79.6009659 50.4089991,79.1233524 L50.4089991,79.1233524 Z"></path>
                </g>
                <g transform="translate(50.000000, 153.000000)" fill="var(--color-image-secondary-1)">
                    <path d="M9.98054192,20.7669 C8.52453359,14.2474412 6.80379648,7.37237567 4.02414423,1.20852378 C3.09759348,-1.04365287 -0.476245135,0.14170326 0.053212437,2.39387991 C1.64158515,8.79480303 4.28887302,15.1957261 7.20088966,21.2410424 C7.73034724,22.4263986 10.2452707,22.1893273 9.98054192,20.7669 L9.98054192,20.7669 Z"></path>
                    <path d="M78.4174376,36.9596765 C84.9328763,35.067668 91.6896276,32.7972579 97.8430976,29.7700444 C100.135567,28.6348393 98.6876915,25.355358 96.3952223,26.1121613 C90.0004398,28.1303037 83.6056573,31.1575172 77.8141562,34.4369985 C76.4869372,34.9415341 76.848906,37.3380782 78.4174376,36.9596765 L78.4174376,36.9596765 Z"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>